import React, { PureComponent } from 'react';

import { PresetChooser } from './PresetChooser.js';
import PropTypes from 'prop-types';
import ToolbarButton from '../../viewer/ToolbarButton';

export class PresetButton extends PureComponent {
  static defaultProps = {
    dropdownVisible: false,
  };

  static propTypes = {
    dropdownVisible: PropTypes.bool.isRequired,
    onChange: PropTypes.func,
  };

  state = {
    dropdownVisible: this.props.dropdownVisible,
  };

  componentDidUpdate(prevProps) {
    if (this.props.dropdownVisible !== prevProps.dropdownVisible) {
      this.setState({
        dropdownVisible: this.props.dropdownVisible,
      });
    }
  }

  onClick = () => {
    this.setState({
      dropdownVisible: !this.state.dropdownVisible,
    });
  };

  onChange = selectedCell => {
    if (this.props.onChange) {
      this.props.onChange(selectedCell);
    }
    this.setState({
      dropdownVisible: !this.state.dropdownVisible,
    });
  };

  render() {
    return (
      <div className="btn-group">
        <ToolbarButton
          className="toolbar-button visible-lg-up"
          isActive={this.state.dropdownVisible}
          label={'Presets'}
          icon="palette"
          onClick={this.onClick}
        />
        <PresetChooser
          visible={this.state.dropdownVisible}
          onChange={this.onChange}
          onClick={this.onClick}
        />
      </div>
    );
  }
}

export default PresetButton;
