import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import './PresetChooser.styl';
import ToolbarButton from '../../viewer/ToolbarButton';
/**
 * Adds the 'hover' class to cells above and to the left of the current cell
 * This is used to "fill in" the grid that the user will change the layout to,
 * if they click on a specific table cell.
 **/

class PresetChooser extends PureComponent {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    onChange: PropTypes.func,
  };

  constructor(props) {
    super(props);
  }

  render() {
    // eslint-disable-next-line no-console
    console.log(window.ohif.app.hotkeysManager);
    const style = {
      display: this.props.visible ? 'block' : 'none',
    };

    const items = () => {
      const preferences = JSON.parse(localStorage.getItem('state')) || {};
      if (
        preferences &&
        preferences.preferences &&
        preferences.preferences.windowLevelData
      ) {
        return Object.values(preferences.preferences.windowLevelData).filter(
          item => item.description
        );
      } else {
        return [
          { description: 'Soft tissue', window: '550', level: '40' },
          { description: 'Lung', window: '150', level: '-600' },
          { description: 'Liver', window: '150', level: '90' },
          { description: 'Bone', window: '2500', level: '480' },
          { description: 'Brain', window: '80', level: '40' },
          { description: 'Trest', window: '1', level: '1' },
        ];
      }
    };

    return (
      <div
        className="presetChooser presetChooser-dropdown-menu"
        role="menu"
        style={style}
      >
        {items().map((item, inx) => (
          <ToolbarButton
            className="toolbar-button visible"
            key={inx}
            label={item.description}
            icon="palette"
            onClick={() => {
              this.props.onChange({ preset: `windowLevelPreset${inx + 1}` });
            }}
          />
        ))}
      </div>
    );
  }
}

export { PresetChooser };
