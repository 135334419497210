import './OHIFLogo.css';
import React from 'react';

function OHIFLogo() {
  return (
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    <a
      alt="OHIF"
      target="_blank"
      rel="noopener noreferrer"
      className="header-brand"
      href="http://ohif.org"
    ></a>
  );
}

export default OHIFLogo;
